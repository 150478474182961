import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { mobileAbout, about, aboutText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFImage src={about} w="100%" alt="Promotions" />
      </MobileScreen>
      <DefaultScreen>
        <CFView column center>
          <CFView
            row
            justifyStart
            alignCenter
            pv="10%"
            pr="40px"
            h="520px"
            w="100%"
            maxWidth="1400px"
            image={`url(${about}) center / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
          >
            {/* <CFImage src={aboutText} h="600px" alt="About" /> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}

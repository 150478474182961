import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, mobileLogo, instagram } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center>
          <CFImage w="85%" src={mobileLogo} alt="Sushi Shun Logo" mt="15px" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          w="100%"
          justifyStart
          h="50px"
          bg="rgba(0, 0, 0, 0.7)"
          zIndex={98}
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFImage
              h="130px"
              mt="95px"
              ml="2%"
              src={logo}
              alt="Sushi Shun Logo"
              zIndex={98}
            />
            <CFView row center mr="1%">
              <CFView
                mr="15px"
                bold
                style="font-size: 19px; letter-spacing: 0.04em; color: #BD9200;"
              >
                FOLLOW US:
              </CFView>
              <CFLink href="https://www.instagram.com/sushishun20/">
                <CFImage
                  w="32px"
                  mt="5px"
                  src={instagram}
                  alt="Sushi Shun Instagram"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column center>
          <CFView h3 futura bold color="#BD9200" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white bold>
            <CFView h4 mb="15px" textCenter maxWidth="75vw">
              Sushi Shun is now taking online orders. Order through our website
              or download our app in your app store! Collect points and redeem
              fantastic rewards!
            </CFView>
            <CFView row center>
              <CFView mr="10px">
                <CFLink href="http://onelink.to/sushishun">
                  <CFImage h="40px" src={appStore} alt="Sushi Shun App Store" />
                </CFLink>
              </CFView>
              <CFLink href="http://onelink.to/sushishun">
                <CFImage
                  h="40px"
                  src={googlePlay}
                  alt="Sushi Shun Google Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h3 futura bold color="#BD9200" mb="20px" minWidth="250px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white bold>
            <CFView maxWidth="260px" fontSize="13px" mb="20px">
              Sushi Shun is now taking online orders. Order through our website
              or download our app in your app store! Collect points and redeem
              fantastic rewards!
            </CFView>
            <CFView row justifyStart>
              <CFLink href="http://onelink.to/sushishun">
                <CFImage
                  h="35px"
                  src={appStore}
                  alt="Sushi Shun App Store"
                  hover
                />
              </CFLink>
              <CFLink href="http://onelink.to/sushishun">
                <CFImage
                  h="35px"
                  ml="5px"
                  src={googlePlay}
                  alt="Sushi Shun Google Play"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
